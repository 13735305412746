export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const CENTER_MODULE_DEMO = "centerModalDemo";

//declare the module name
export const GET_STARTED_MODAL = "GetStartedModal"
export const GET_IN_TOUCH = "GetInTouchModal";
export const SHOW_NEXT_MODAL = "ShowNextModal"
export const THANKYOU_MODAL = "ThankyouModal"
export const TOPIC_SELECTION = "TopicModal";
export const DOWNLOAD_BROUCHER_MODAL = "DownloadBroucherModal"

export function showModal(type, ownProps) {
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
  
    return {
      type: SHOW_MODAL,
      modalType: type,
      scrollPosition: scrollPosition,
      ownProps,
    };
  }
  
  export function hideModal() {
    return {
      type: HIDE_MODAL,
      modalType: null,
    };
  }