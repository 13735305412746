import * as feedAction from '../actions/feed.actions';
const initialState = {
  imagesArr: [],

  loading: false,
  interestLoader: false,
  error: null,

  userIntrestList: null,

  addUserIntrestDetails: null,
  addUserIntrestStatus: '',

  recommendedList: null,
  topPicksList: null,

  postFeedDetails: null,
  postFeedLoader: false,
  feedLoader: false,
  feedStatus: null,
  feeds: [],
  previousFeed: [],
  userProfileInfo: null,
  labelsList: null,
  postLikeDetails: [],
  postCommentLikeDetails: [],
  comments: null,
  feedPostCommentDetails: null,

  postPollAnswerDetails: null,
  postPollAnswerStatus: null,
  postPollAnswerLoader: false,
  postPollAnswerError: null,
  feedBookmarkDetails: null,

  feedDeleteStatus: null,
  feedDeleteData: null,
  feedDeleteError: null,

  feedDetails: null,
  feedDetailsLoader: false,
  feedDetailsError: null,
  feedDetailsStatus: null,
  feedDetailsErrorMessage: null,

  reportDetail: null,
  commentsOfComment: null,
};

export function feedReducer(state = initialState, action) {
  switch (action.type) {
    case feedAction.GET_FEED_DETAILS_REQUEST:
      return Object.assign({}, state, {
        feedDetailsStatus: action.status,
        feedDetailsLoader: true,
        feedDetails: null,
      });

    case feedAction.GET_FEED_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        feedDetailsStatus: action.status,
        feedDetailsLoader: false,
        feedDetails: action.feedDetails,
      });
    case feedAction.GET_FEED_DETAILS_FAILURE:
      return Object.assign({}, state, {
        feedDetailsStatus: action.status,
        feedDetailsLoader: false,
        feedDetailsErrorMessage: action.errorMessage,
        feedDetailsError: action.error,
      });
    case feedAction.UPDATE_IMAGE_ARR:
      return {
        ...state,
        imagesArr: action.data,
      };
    case feedAction.ADD_USER_INTEREST_REQUEST:
      return Object.assign({}, state, {
        addUserIntrestStatus: action.status,
        loader: true,
        interestLoader: true,
        addUserIntrestDetails: null,
      });

    case feedAction.ADD_USER_INTEREST_SUCCESS:
      return Object.assign({}, state, {
        addUserIntrestStatus: action.status,
        loader: false,
        interestLoader: false,
        addUserIntrestDetails: action.userDetails,
      });
    case feedAction.ADD_USER_INTEREST_FAILURE:
      return Object.assign({}, state, {
        addUserIntrestStatus: action.status,
        loader: false,
        interestLoader: false,
        error: action.error,
      });
    case feedAction.GET_USER_INTRESET_REQUEST:
      return Object.assign({}, state, {
        addUserIntrestDetails: null,
        status: action.status,
        loading: true,
        interestLoading: true,
        // userIntrestList: null,
      });

    case feedAction.GET_USER_INTRESET_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        interestLoading: false,
        userIntrestList: action.userDetails,
      });
    case feedAction.GET_USER_INTRESET_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
        interestLoading: false,
      });
    case feedAction.GET_RECOMMANDATION_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loader: true,
        recommendedList: null,
      });

    case feedAction.GET_RECOMMANDATION_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loader: false,
        recommendedList: action.recommands,
      });
    case feedAction.GET_RECOMMANDATION_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loader: false,
        error: action.error,
      });
    case feedAction.GET_TOP_PICKS_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        topPickloader: true,
        topPicksList: null,
      });

    case feedAction.GET_TOP_PICKS_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        topPickloader: false,
        topPicksList: action.topPicks,
      });
    case feedAction.GET_TOP_PICKS_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        topPickloader: false,
        error: action.error,
      });
    case feedAction.POST_FEED_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        postFeedLoader: false,
        error: action.error,
      });
    case feedAction.POST_FEED_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        postFeedDetails: false,
        postFeedLoader: true,
      });

    case feedAction.POST_FEED_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        postFeedLoader: false,
        postFeedDetails: action.feedDetails,
      });
    case feedAction.GET_FEED_REQUEST:
      return Object.assign({}, state, {
        feedStatus: action.status,
        postFeedDetails: null,
        feedLoader: true,
      });

    case feedAction.GET_FEED_SUCCESS:
      let userFeeds = action.feeds && action.feeds.userFeeds;
      let eventFeeds = action.feeds && action.feeds.eventFeeds;
      let marketPlaceFeed = action.feeds && action.feeds.marketPlaceFeed;
      let blogFeeds = action.feeds && action.feeds.blogFeeds;
      let heighestLength = userFeeds && userFeeds.length;
      if (eventFeeds && eventFeeds.length > heighestLength) {
        if (eventFeeds.length > blogFeeds.length) {
          heighestLength = eventFeeds.length;
        } else if (blogFeeds.length > heighestLength) {
          heighestLength = blogFeeds.length;
        }
      }
      const mixFeed = [];
      let flag = 0;
      for (let i = 0; i < heighestLength; i++) {
        userFeeds[flag] && mixFeed.push(userFeeds[flag]);
        flag = flag + 1;
        userFeeds[flag] && mixFeed.push(userFeeds[flag]);
        flag = flag + 1;
        userFeeds[flag] && mixFeed.push(userFeeds[flag]);
        blogFeeds[i] && mixFeed.push(blogFeeds[i]);
        flag = flag + 1;
        userFeeds[flag] && mixFeed.push(userFeeds[flag]);
        flag = flag + 1;
        userFeeds[flag] && mixFeed.push(userFeeds[flag]);
        flag = flag + 1;
        userFeeds[flag] && mixFeed.push(userFeeds[flag]);
        flag = flag + 1;
        eventFeeds[i] && mixFeed.push(eventFeeds[i]);
        flag = flag + 1;
        marketPlaceFeed[i] && mixFeed.push(marketPlaceFeed[i]);
      }

      let searchResults = [...action.oldFeed, ...mixFeed];
      return Object.assign({}, state, {
        feedStatus: action.status,
        feedLoader: false,
        feeds: searchResults,
      });
    case feedAction.GET_FEED_FAILURE:
      return Object.assign({}, state, {
        feedStatus: action.status,
        feedLoader: false,
        error: action.error,
      });

    case feedAction.GET_USER_PROFILE_INFO_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loader: true,
      });

    case feedAction.GET_USER_PROFILE_INFO_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loader: false,
        userProfileInfo: action.userProfileInfo,
      });
    case feedAction.GET_USER_PROFILE_INFO_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loader: false,
        error: action.error,
      });
    case feedAction.GET_LABELS_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });

    case feedAction.GET_LABELS_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        labelsList: action.labelsList,
      });
    case feedAction.GET_LABELS_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });
    case feedAction.POST_FEED_LIKE_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
      });

    case feedAction.POST_FEED_LIKE_SUCCESS:
      let feedLike = [...action.oldLikes, action.postLikeDetails];
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        postLikeDetails: feedLike,
      });
    case feedAction.POST_FEED_LIKE_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case feedAction.POST_FEED_COMMENT_LIKE_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
      });

    case feedAction.POST_FEED_COMMENT_LIKE_SUCCESS:
      let likes = [...action.oldLikes, action.postLikeDetails];
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        postCommentLikeDetails: likes,
      });
    case feedAction.POST_FEED_COMMENT_LIKE_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case feedAction.GET_FEED_COMMENT_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        feedPostCommentDetails: null,
      });

    case feedAction.GET_FEED_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        comments: action.comments,
      });
    case feedAction.GET_FEED_COMMENT_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case feedAction.POST_FEED_COMMENT_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
      });

    case feedAction.POST_FEED_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        feedPostCommentDetails: action.feedPostCommentDetails,
      });
    case feedAction.POST_FEED_COMMENT_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case feedAction.POST_POLL_ANSWER_REQUEST:
      return Object.assign({}, state, {
        postPollAnswerStatus: action.status,
        postPollAnswerLoader: true,
      });

    case feedAction.POST_POLL_ANSWER_SUCCESS:
      let answerDetails = [];
      if (action.oldPostData) {
        answerDetails.push(...action.oldPostData);
      }
      answerDetails.push(action.pollAnswerDetails);
      return Object.assign({}, state, {
        postPollAnswerDetails: answerDetails,
        postPollAnswerStatus: action.status,
        postPollAnswerLoader: false,
      });
    case feedAction.POST_POLL_ANSWER_FAILURE:
      return Object.assign({}, state, {
        postPollAnswerStatus: action.status,
        postPollAnswerLoader: false,
        postPollAnswerError: action.error,
      });
    case feedAction.POST_FEED_BOOKMARK_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
      });

    case feedAction.POST_FEED_BOOKMARK_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        feedBookmarkDetails: action.feedBookmarkDetails,
      });
    case feedAction.POST_FEED_BOOKMARK_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });

    case feedAction.DELETE_FEED_COMMENT_REQUEST:
      return Object.assign({}, state, {
        feedCommentDeleteStatus: action.status,
      });

    case feedAction.DELETE_FEED_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        feedCommentDeleteStatus: action.status,
        feedCommentDeleteData: action.comments,
      });

    case feedAction.DELETE_FEED_COMMENT_FAILURE:
      return Object.assign({}, state, {
        feedCommentDeleteStatus: action.status,
        feedCommentDeleteError: action.error,
      });

    case feedAction.DELETE_FEED_REQUEST:
      return Object.assign({}, state, {
        feedDeleteStatus: action.status,
      });

    case feedAction.DELETE_FEED_SUCCESS:
      return Object.assign({}, state, {
        feedDeleteStatus: action.status,
        feedDeleteData: action.feeds,
      });
    case feedAction.DELETE_FEED_FAILURE:
      return Object.assign({}, state, {
        feedDeleteStatus: action.status,
        feedDeleteError: action.error,
      });
    case feedAction.REPORT_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });

    case feedAction.REPORT_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        reportDetail: action.reportDetail,
      });
    case feedAction.REPORT_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });
    case feedAction.GET_FEED_COMMENTS_OF_COMMENT_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: true,
        // blogPostCommentDetails: null
      });

    case feedAction.GET_FEED_COMMENTS_OF_COMMENT_SUCCESS:
      let comments = [...action.oldComment, action.commentsOfComment];
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        commentsOfComment: comments,
      });
    case feedAction.GET_FEED_COMMENTS_OF_COMMENT_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case feedAction.POST_FEED_COMMENT_OF_COMMENT_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
      });

    case feedAction.POST_FEED_COMMENT_OF_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        feedPostCommentsOfCommentDetails:
          action.feedPostCommentsOfCommentDetails,
      });
    case feedAction.POST_FEED_COMMENT_OF_COMMENT_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
}
export default feedReducer;
