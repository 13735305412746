import * as bookMarkAction from "../actions/bookMark.action";

const bookMark = (
  state = {
    status: null,
    error: null,
    loading: false,
    bookMarkDetails: []
  },
  action
) => {
  switch (action.type) {
    case bookMarkAction.GET_BOOKMARK_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: true
      });

    case bookMarkAction.GET_BOOKMARK_SUCCESS:
      let bookMarkResult = [...action.oldBlog, ...action.bookMarkDetails];
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        bookMarkDetails: bookMarkResult
      });
    case bookMarkAction.GET_BOOKMARK_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error
      });

    default:
      return state;
  }
};

export default bookMark;
