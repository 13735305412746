import * as eventAction from "../actions/blog.action";
import * as bookMarkAction from "../actions/bookMark.action";
const blog = (
  state = {
    status: null,
    error: null,
    loading: false,
    allBlogsDetails: null,
    blogDetails: null,
    blogLikeDetails: [],
    subscribtionDetails: null,
    blogPostCommentDetails: null,
    commentsOfComment: null,
    blogBookmarkDetails: null,
    bookMarkDetails: [],
    bookMarkLoader: false,
    blogLoader: false,
    empty: false,
    commentsEmpty: false,
    commentLoader: false,
    filterList: null,
    filterLoader: false,
  },
  action
) => {
  switch (action.type) {
    case eventAction.GET_BLOG_FILTER_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        pastBlogDetails: [],
        filterLoader: true,
      });

    case eventAction.GET_BLOG_FILTER_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        filterLoader: false,
        blogFilterList: action.blogFilterList,
      });
    case eventAction.GET_BLOG_FILTER_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        filterLoader: false,
        error: action.error,
      });

    case eventAction.GET_BLOG_FILTER_DETAIL_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        Detailloading: true,
        DetailsEmpty: false,
      });

    case eventAction.GET_BLOG_FILTER_DETAIL_SUCCESS:
      let oldBlogDetails = [
        ...action.oldDetails,
        ...action.blogFilterDetailList,
      ];
      return Object.assign({}, state, {
        status: action.status,
        Detailloading: false,
        blogFilterDetailList: oldBlogDetails,
        DetailsEmpty: action.blogFilterDetailList.length === 0 ? true : false,
      });

    case eventAction.GET_BLOG_FILTER_DETAIL_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        Detailloading: false,
        error: action.error,
        DetailsEmpty: false,
      });

    case eventAction.DELETE_BLOG_COMMENT_REQUEST:
      return Object.assign({}, state, {
        blogCommentDeleteStatus: action.status,
      });

    case eventAction.DELETE_BLOG_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        blogCommentDeleteStatus: action.status,
        blogCommentDeleteData: action.comments,
      });

    case eventAction.DELETE_BLOG_COMMENT_FAILURE:
      return Object.assign({}, state, {
        blogCommentDeleteStatus: action.status,
        blogCommentDeleteError: action.error,
      });

    case eventAction.CLEAR_ALL_REQUEST:
      return Object.assign({}, state, {
        subscribtionDetails: null,
      });
    case eventAction.GET_ALL_BLOGS_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        blogLoader: true,
        empty: false,
      });

    case eventAction.GET_ALL_BLOGS_SUCCESS:
      let searchResults = [...action.oldBlog, ...action.blogsDetails];
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        allBlogsDetails: searchResults,
        blogLoader: false,
        empty: action.blogsDetails.length === 0 ? true : false,
      });

    case eventAction.GET_ALL_BLOGS_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
        blogLoader: false,
        empty:
          action.error === "Request failed with status code 404" ? true : false,
      });
    case eventAction.GET_BLOG_DETAILS_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: true,
        blogPostCommentDetails: null,
      });

    case eventAction.GET_BLOG_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        blogDetails: action.blogsDetails,
      });
    case eventAction.GET_BLOG_DETAILS_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case eventAction.POST_BLOG_LIKE_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
      });

    case eventAction.POST_BLOG_LIKE_SUCCESS:
      let blogLike = [...action.oldLikes, action.blogLikeDetails];
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        blogLikeDetails: blogLike,
      });
    case eventAction.POST_BLOG_LIKE_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case eventAction.GET_BLOG_COMMENT_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        blogPostCommentDetails: null,
        commentLoader: true,
      });

    case eventAction.GET_BLOG_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        comments: [...action.oldComments, ...action.comments],
        commentsEmpty:
          action.comments &&
          action.comments.status &&
          action.comments.status === 200
            ? true
            : false,
        commentLoader: false,
      });
    case eventAction.GET_BLOG_COMMENT_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
        commentsEmpty:
          action.error === "action.comments is not iterable" ? true : false,
      });
    case eventAction.POST_BLOG_COMMENT_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        commentLoader: false,
      });

    case eventAction.POST_BLOG_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        blogPostCommentDetails: action.blogPostCommentDetails,
      });
    case eventAction.POST_BLOG_COMMENT_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case eventAction.GET_BLOG_COMMENTS_OF_COMMENT_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: true,
        blogPostCommentDetails: null,
      });

    case eventAction.GET_BLOG_COMMENTS_OF_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        commentsOfComment: action.commentsOfComment,
      });
    case eventAction.GET_BLOG_COMMENTS_OF_COMMENT_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case eventAction.SUBSCRIPTION_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: true,
      });

    case eventAction.SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        subscribtionDetails: action.userDetails,
      });
    case eventAction.SUBSCRIPTION_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case eventAction.POST_BLOG_BOOKMARK_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
      });

    case eventAction.POST_BLOG_BOOKMARK_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        blogBookmarkDetails: action.blogBookmarkDetails,
      });
    case eventAction.POST_BLOG_BOOKMARK_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
      });
    case bookMarkAction.GET_BOOKMARK_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        bookMarkLoader: true,
      });

    case bookMarkAction.GET_BOOKMARK_SUCCESS:
      let bookMarkResult = [...action.oldBlog, ...action.bookMarkDetails];
      return Object.assign({}, state, {
        status: action.status,
        blogBookmarkDetails: null,
        bookMarkLoader: false,
        bookMarkDetails: bookMarkResult,
      });
    case bookMarkAction.GET_BOOKMARK_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        bookMarkLoader: false,
        error: action.error,
      });
    default:
      return state;
  }
};
export default blog;
