import { SUCCESS, REQUESTING, ERROR } from "../utils/constant";
import { get, post } from "../utils/apiRequest.js";
import { displayToast } from "../components/modules/toast.actions";

export const GET_ALL_MEDIA_PARTNER_REQUEST = "GET_ALL_MEDIA_PARTNER_REQUEST";
export const GET_ALL_MEDIA_PARTNER_SUCCESS = "GET_ALL_MEDIA_PARTNER_SUCCESS";
export const GET_ALL_MEDIA_PARTNER_FAILURE = "GET_ALL_MEDIA_PARTNER_FAILURE";

export const GET_BLOGS_REQUEST = "GET_BLOGS_REQUEST";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAILURE = "GET_BLOGS_FAILUER"

export const POST_CONTACT_REQUEST = 'POST_CONTACT_REQUEST';
export const POST_CONTACT_SUCCESS = 'POST_CONTACT_SUCCESS';
export const POST_CONTACT_FAILURE = 'POST_CONTACT_FAILURE'; 

export const POST_STARTED_REQUEST = 'POST_STARTED_REQUEST';
export const POST_STARTED_SUCCESS = 'POST_STARTED_SUCCESS';
export const POST_STARTED_FAILURE = 'POST_STARTED_FAILURE';

export const GET_BLOG_FILTER_DETAIL_REQUEST = 'GET_BLOG_FILTER_DETAIL_REQUEST';
export const GET_BLOG_FILTER_DETAIL_SUCCESS = 'GET_BLOG_FILTER_DETAIL_SUCCESS';
export const GET_BLOG_FILTER_DETAIL_FAILURE = 'GET_BLOG_FILTER_DETAIL_FAILURE';

export const POST_DEVICE_DETAILS_REQUEST = 'POST_DEVICE_DETAILS_REQUEST'
export const POST_DEVICE_DETAILS_SUCCESS = 'POST_DEVICE_DETAILS_SUCCESS'
export const POST_DEVICE_DETAILS_FAILURE = 'POST_DEVICE_DETAILS_FAILURE'

export function getAllMediaRequest() {
  return {
    type: GET_ALL_MEDIA_PARTNER_REQUEST,
    status: REQUESTING,
  };
}

export function getAllMediaSuccess(mediaPartnerData) {
  return {
    type: GET_ALL_MEDIA_PARTNER_SUCCESS,
    status: SUCCESS,
    mediaPartnerData,
  };
}

export function getAllMediaFailure(error) {
  return {
    type: GET_ALL_MEDIA_PARTNER_FAILURE,
    status: ERROR,
    error,
  };
}

export function getAllMedia() {
  return async (dispatch) => {
    dispatch(getAllMediaRequest());
    try {
      let url = "media-partner";
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getAllMediaSuccess(resultJson));
    } catch (e) {
      return dispatch(getAllMediaFailure(e.message));
    }
  };
}

export function filterDetailRequest() {
  return {
    type: GET_BLOG_FILTER_DETAIL_REQUEST,
    status: REQUESTING,
  };
}
export function filterDetailSuccess(blogFilterDetailList, oldDetails) {
  return {
    type: GET_BLOG_FILTER_DETAIL_SUCCESS,
    status: SUCCESS,
    blogFilterDetailList,
    oldDetails: oldDetails ? oldDetails : [],
  };
}
export function filterDetailFailure(error) {
  return {
    type: GET_BLOG_FILTER_DETAIL_FAILURE,
    status: ERROR,
    error,
  };
}

export function getBlogFilterDetailList(id, page, typeId, oldDetails) {
  return async (dispatch) => {
    dispatch(filterDetailRequest());
    try {
      let url = `blogs?filter={"blogCategory":[${id}],"contentTypeId":[${typeId}]}&page=${page}&limit=10`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(filterDetailSuccess(resultJson, oldDetails));
    } catch (e) {
      return dispatch(filterDetailFailure(e.message));
    }
  };
}

export function getBlogsRequest() {
  return {
    type: GET_BLOGS_REQUEST,
    status: REQUESTING,
  }
}

export function getBlogsSuccess(blogsDetails, oldBlog) {
  return {
    type: GET_BLOGS_SUCCESS,
    status: SUCCESS,
    blogsDetails: blogsDetails === 0 ? [] : blogsDetails,
    oldBlog: oldBlog ? oldBlog : [],
  }
}

export function getBlogsFailure(error) {
  return {
    type: GET_BLOGS_FAILURE,
    status: ERROR,
    error,
  };
}

export function getBlogsForCorporate(id, blogType, typeId, oldBlog) {
  return async (dispatch) => {
    dispatch(getBlogsRequest());
    try {
      let url = `blogs?limit=3&page=1`
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message)
      }
      return dispatch(getBlogsSuccess(resultJson, oldBlog))
    }catch(e){
      return dispatch(getBlogsFailure(e.message))
    }
  }
}

export function postContactRequest() {
  return {
    type: POST_CONTACT_REQUEST,
    status: REQUESTING,
  };
}

export function postContactSuccess(postContactDetails) {
  return {
    type: POST_CONTACT_SUCCESS,
    status: SUCCESS,
    postContactDetails,
  };
}
export function postContactFailure(error) {
  return {
    type: POST_CONTACT_FAILURE,
    status: ERROR,
    error,
  };
}

export function postStartedRequest() {
  return {
    type: POST_STARTED_REQUEST,
    status: REQUESTING
  }
}

export function postStartedSuccess(payload) {
  return {
    type: POST_STARTED_SUCCESS,
    status: SUCCESS,
    payload
  }
}

export function postStartedFailure(error) {
  return {
    type: POST_STARTED_FAILURE,
    status: ERROR,
    error
  }
}

export function postDeviceDetailsRequest() {
    return {
        type: POST_DEVICE_DETAILS_REQUEST,
        status: REQUESTING
    }
}

export function postDeviceDetailsSuccess(deviceId) {
    return {
        type: POST_DEVICE_DETAILS_SUCCESS,
        status: SUCCESS,
        deviceId
    }
}

export function postDeviceDetailsFailure(error) {
    return {
        type:POST_DEVICE_DETAILS_FAILURE,
        status: ERROR,
        error
    }
}

export function postContactUsNew(userLoginDetails) {
  return async (dispatch) => {
    dispatch(postContactRequest());
    try {
      let url = `brouchure`;
      const result = await post(url, userLoginDetails);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, resultJson.message));
      return dispatch(postContactSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(postContactFailure(e.message));
    }
  };
}

export function postStarted(formData) {
  return async (dispatch) => {
    dispatch(postStartedRequest());
    try {
      let url = `sentmail`;
      const result = await post(url, formData);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message)
      }
      return dispatch(postStartedSuccess(result.status));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(postStartedFailure(e.message))
    }
  }
}

export function postDeviceDetails(pageType) {
    return async (dispatch) => {
        dispatch(postDeviceDetailsRequest())
        try{
            let url = `globalEvents/global-devices?pageType=${pageType}`
            const result = await get(url)
            const resultJson = await result.data
            if (resultJson.error) {
                throw new Error(resultJson.message)
            }
            dispatch(postDeviceDetailsSuccess(resultJson.deviceId))
        } catch (e) {
            dispatch(postDeviceDetailsFailure(e.message))
            throw e
        }
    }
}
