import { combineReducers } from "redux";

import verifyemail from "./verifyemail.reducer";
import corporate from "./corporate.reducer";
import blog from "./blog.reducer";
import bookMark from "./bookmark.reducer";
import globalEvents from "./globalEvents.reducer";
import modal from "../components/modules/modal.reducers";
import feedReducer from "./feed.reducer";
import event from "./event.reducer";

export default combineReducers({
    verifyemail,
    corporate,
    blog,
    bookMark,
    globalEvents,
    modal,
    feedReducer,
    event
})