import {
  SUCCESS,
  REQUESTING,
  ERROR,
  USER_DETAILS,
} from '../utils/constant';
import * as Cookie from '../utils/Cookie';
import { get, post, put } from '../utils/apiRequest.js';
import { displayToast } from '../components/modules/toast.actions';

export const GET_ALL_EVENTS_REQUEST = 'GET_ALL_EVENTS_REQUEST';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAILURE = 'GET_ALL_EVENTS_FAILURE';

export const GET_ALL_PAST_EVENTS_REQUEST = 'GET_ALL_PAST_EVENTS_REQUEST';
export const GET_ALL_PAST_EVENTS_SUCCESS = 'GET_ALL_PAST_EVENTS_SUCCESS';
export const GET_ALL_PAST_EVENTS_FAILURE = 'GET_ALL_PAST_EVENTS_FAILURE';

export const GET_EVENT_DETAILS_REQUEST = 'GET_EVENT_DETAILS_REQUEST';
export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS';
export const GET_EVENT_DETAILS_FAILURE = 'GET_EVENT_DETAILS_FAILURE';

export const GET_FILTER_REQUEST = 'GET_FILTER_REQUEST';
export const GET_FILTER_SUCCESS = 'GET_FILTER_SUCCESS';
export const GET_FILTER_FAILURE = 'GET_FILTER_FAILURE';

export const GET_REGISTER_EVENT_DETAILS_REQUEST =
  'GET_REGISTER_EVENT_DETAILS_REQUEST';
export const GET_REGISTER_EVENT_DETAILS_SUCCESS =
  'GET_REGISTER_EVENT_DETAILS_SUCCESS';
export const GET_REGISTER_EVENT_DETAILS_FAILURE =
  'GET_REGISTER_EVENT_DETAILS_FAILURE';

export const BOOK_EVENT_REQUEST = 'BOOK_EVENT_REQUEST';
export const BOOK_EVENT_SUCCESS = 'BOOK_EVENT_SUCCESS';
export const BOOK_EVENT_FAILURE = 'BOOK_EVENT_FAILURE';

export const PAYMENT_STATUS_REQUEST = 'PAYMENT_STATUS_REQUEST';
export const PAYMENT_STATUS_SUCCESS = 'PAYMENT_STATUS_SUCCESS';
export const PAYMENT_STATUS_FAILURE = 'PAYMENT_STATUS_FAILURE';

export const GET_LABEL_REQUESTING = 'GET_LABEL_REQUESTING';
export const GET_LABEL_SUCCESS = 'GET_LABEL_SUCCESS';
export const GET_LABEL_FAILURE = 'GET_LABEL_FAILURE';

export const GET_TESTIMONIALS_REQUESTING = 'GET_TESTIMONIALS_REQUESTING';
export const GET_TESTIMONIALS_SUCCESS = 'GET_TESTIMONIALS_SUCCESS';
export const GET_TESTIMONIALS_FAILURE = 'GET_TESTIMONIALS_FAILURE';

export const ADD_USER_INTEREST_REQUEST = 'ADD_USER_INTEREST_REQUEST';
export const ADD_USER_INTEREST_SUCCESS = 'ADD_USER_INTEREST_SUCCESS';
export const ADD_USER_INTEREST_FAILURE = 'ADD_USER_INTEREST_FAILURE';

export const GET_CITY_LIST_REQUEST = 'GET_CITY_LIST_REQUEST';
export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS';
export const GET_CITY_LIST_FAILURE = 'GET_CITY_LIST_FAILURE';

export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE';

export const GET_INTRESET_REQUEST = 'GET_INTRESET_REQUEST';
export const GET_INTRESET_SUCCESS = 'GET_INTRESET_SUCCESS';
export const GET_INTRESET_FAILURE = 'GET_INTRESET_FAILURE';

export const GET_SKILL_LEVELS_REQUEST = 'GET_SKILL_LEVELS_REQUEST';
export const GET_SKILL_LEVELS_SUCCESS = 'GET_SKILL_LEVELS_SUCCESS';
export const GET_SKILL_LEVELS_FAILURE = 'GET_SKILL_LEVELS_FAILURE';

export const POST_REVIEW_REQUEST = 'POST_REVIEW_REQUEST';
export const POST_REVIEW_SUCCESS = 'POST_REVIEW_SUCCESS';
export const POST_REVIEW_FAILURE = 'POST_REVIEW_FAILURE';

export const POST_INTEREST_REQUEST = 'POST_INTEREST_REQUEST';
export const POST_INTEREST_SUCCESS = 'POST_INTEREST_SUCCESS';
export const POST_INTEREST_FAILURE = 'POST_INTEREST_FAILURE';

export const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAILURE = 'GET_BANNER_FAILURE';

export function getBannerRequest() {
  return {
    type: GET_BANNER_REQUEST,
    status: REQUESTING,
  };
}

export function getBannerSuccess(bannerData) {
  return {
    type: GET_BANNER_SUCCESS,
    status: SUCCESS,
    bannerData,
  };
}

export function getBannerFailure(error) {
  return {
    type: GET_BANNER_FAILURE,
    status: ERROR,
    error,
  };
}

export function getBanner() {
  return async (dispatch) => {
    dispatch(getBannerRequest());
    try {
      let url = `virtualLearning/banner`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getBannerSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(2, 'No data available'));
      return dispatch(getBannerFailure(e.message));
    }
  };
}

export function postInterestRequest() {
  return {
    type: POST_INTEREST_REQUEST,
    status: REQUESTING,
  };
}
export function postInterestSuccess(interestDetails) {
  return {
    type: POST_INTEREST_SUCCESS,
    status: SUCCESS,
    interestDetails,
  };
}

export function postInterestFailure(error) {
  return {
    type: POST_INTEREST_FAILURE,
    status: ERROR,
    error,
  };
}

export function postInterest(data) {
  return async (dispatch) => {
    dispatch(postInterestRequest());
    try {
      let url = `virtualLearning/showInterest`;
      const result = await post(url, data);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, 'Thank You'));
      return dispatch(postInterestSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(2, 'No data available'));
      return dispatch(postInterestFailure(e.message));
    }
  };
}

export function postReviewRequest() {
  return {
    type: POST_REVIEW_REQUEST,
    status: REQUESTING,
  };
}

export function postReviewSuccess(review) {
  return {
    type: POST_REVIEW_SUCCESS,
    status: SUCCESS,
    review,
  };
}

export function postReviewFailure(error) {
  return {
    type: POST_REVIEW_FAILURE,
    status: ERROR,
    error,
  };
}

export function postReview(data) {
  return async (dispatch) => {
    dispatch(postReviewRequest());
    try {
      let url = `virtualLearning/review`;
      const result = await post(url, data);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, 'Your review has been added'));
      dispatch(getTestimonials(data.eventId));
      dispatch(getEventDetails(data.eventId));
      return dispatch(postReviewSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(2, 'No data available'));
      return dispatch(postReviewFailure(e.message));
    }
  };
}

export function getSkillLevelsRequest() {
  return {
    type: GET_SKILL_LEVELS_REQUEST,
    status: REQUESTING,
  };
}

export function getSkillLevelsSuccess(skillLevels) {
  return {
    type: GET_SKILL_LEVELS_SUCCESS,
    status: SUCCESS,
    skillLevels,
  };
}

export function getSkillLevelsFailure(error) {
  return {
    type: GET_SKILL_LEVELS_FAILURE,
    status: ERROR,
    error,
  };
}

export function getSkillLevels() {
  return async (dispatch) => {
    dispatch(getSkillLevelsRequest());
    try {
      let url = `virtualLearning/skillLevels`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getSkillLevelsSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(2, 'No data available'));
      return dispatch(getSkillLevelsFailure(e.message));
    }
  };
}

export function getLabelRequest() {
  return {
    type: GET_LABEL_REQUESTING,
    status: REQUESTING,
  };
}

export function getLabelSuccess(labels) {
  return {
    type: GET_LABEL_SUCCESS,
    status: SUCCESS,
    labels,
  };
}

export function getLabelFailure(error) {
  return {
    type: GET_LABEL_FAILURE,
    status: ERROR,
    error,
  };
}

export function getLabels() {
  return async (dispatch) => {
    dispatch(getLabelRequest());
    try {
      let url = `labels?limit=100`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getLabelSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(2, 'No data available'));
      return dispatch(getLabelFailure(e.message));
    }
  };
}

export function getTestimonialsRequest() {
  return {
    type: GET_TESTIMONIALS_REQUESTING,
    status: REQUESTING,
  };
}

export function getTestimonialsSuccess(testimonials) {
  return {
    type: GET_TESTIMONIALS_SUCCESS,
    status: SUCCESS,
    testimonials,
  };
}

export function getTestimonialsFailure(error) {
  return {
    type: GET_TESTIMONIALS_FAILURE,
    status: ERROR,
    error,
  };
}

export function getTestimonials(eventId) {
  return async (dispatch) => {
    dispatch(getTestimonialsRequest());
    try {
      let url = `virtualLearning/${eventId}/feedback`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getTestimonialsSuccess(resultJson));
    } catch (e) {
      // dispatch(displayToast("No data available"));
      return dispatch(getTestimonialsFailure(e.message));
    }
  };
}

export function getAllPastEventsRequest() {
  return {
    type: GET_ALL_PAST_EVENTS_REQUEST,
    status: REQUESTING,
  };
}

export function getAllPastEventsSuccess(eventDetails, oldEvent) {
  return {
    type: GET_ALL_PAST_EVENTS_SUCCESS,
    status: SUCCESS,
    eventDetails,
    oldEvent,
  };
}

export function getAllPastEventsFailure(oldEvent, error) {
  return {
    type: GET_ALL_PAST_EVENTS_FAILURE,
    status: ERROR,
    oldEvent,
    error,
  };
}

export function getAllPastEvents(filterDetails, page, oldData) {
  let filterData = filterDetails ? filterDetails : null;
  return async (dispatch) => {
    dispatch(getAllPastEventsRequest());
    try {
      let url = `events?limit=10&page=${page}&filter=${filterData}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getAllPastEventsSuccess(resultJson, oldData));
    } catch (e) {
      // dispatch(displayToast("No data available"));
      return dispatch(getAllPastEventsFailure(oldData, e.message));
    }
  };
}

export function getAllEventsRequest(loader) {
  return {
    type: GET_ALL_EVENTS_REQUEST,
    status: REQUESTING,
    loader: loader,
  };
}

export function getAllEventsSuccess(eventDetails, oldEvent) {
  return {
    type: GET_ALL_EVENTS_SUCCESS,
    status: SUCCESS,
    eventDetails: eventDetails.status ? [] : eventDetails,
    oldEvent,
  };
}

export function getAllEventsFailure(error) {
  return {
    type: GET_ALL_EVENTS_FAILURE,
    status: ERROR,
    error,
  };
}

export function getAllEvents(
  filterDetails,
  page,
  vtype,
  stype,
  ctype,
  oldEvent,
  loader,
  limit
) {
  let oldData = oldEvent ? oldEvent : null;
  let lim = limit === undefined || limit === null ? 10000 : limit;
  return async (dispatch) => {
    dispatch(getAllEventsRequest(loader));
    try {
      let url = `virtualLearning?filter={"virtualLearningType": [${vtype}],"labels": [${ctype}], "skillLevel": [${stype}]}&limit=${lim}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getAllEventsSuccess(resultJson, oldData));
    } catch (e) {
      return dispatch(getAllEventsFailure(e.message));
    }
  };
}

export function getEventDetailsRequest() {
  return {
    type: GET_EVENT_DETAILS_REQUEST,
    status: REQUESTING,
  };
}

export function getEventDetailsSuccess(eventDetails) {
  return {
    type: GET_EVENT_DETAILS_SUCCESS,
    status: SUCCESS,
    eventDetails,
  };
}

export function getEventDetailsFailure(error) {
  return {
    type: GET_EVENT_DETAILS_FAILURE,
    status: ERROR,
    error,
  };
}

export function getEventDetails(id) {
  return async (dispatch) => {
    dispatch(getEventDetailsRequest());
    try {
      let user = Cookie.getCookie(USER_DETAILS);
      let parsedUser = user ? JSON.parse(user) : null;
      let userId = parsedUser ? parsedUser.userId : null;
      let url = `virtualLearning/${id}?userId=${userId}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getEventDetailsSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(2, 'No data available'));
      return dispatch(getEventDetailsFailure(e.message));
    }
  };
}

export function getFilterRequest() {
  return {
    type: GET_FILTER_REQUEST,
    status: REQUESTING,
  };
}

export function getFilterSuccess(filterList) {
  return {
    type: GET_FILTER_SUCCESS,
    status: SUCCESS,
    filterList,
  };
}

export function getFilterFailure(error) {
  return {
    type: GET_FILTER_FAILURE,
    status: ERROR,
    error,
  };
}

export function getFilterList() {
  return async (dispatch) => {
    dispatch(getFilterRequest());
    try {
      let url = `events/filters`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getFilterSuccess(resultJson));
    } catch (e) {
      return dispatch(getFilterFailure(e.message));
    }
  };
}

export function getRegisterEventRequest() {
  return {
    type: GET_REGISTER_EVENT_DETAILS_REQUEST,
    status: REQUESTING,
  };
}

export function getRegisterEventSuccess(registerList) {
  return {
    type: GET_REGISTER_EVENT_DETAILS_SUCCESS,
    status: SUCCESS,
    registerList,
  };
}

export function getRegisterEventFailure(error) {
  return {
    type: GET_REGISTER_EVENT_DETAILS_FAILURE,
    status: ERROR,
    error,
  };
}

export function getRegisterEvent(id) {
  return async (dispatch) => {
    dispatch(getRegisterEventRequest());
    try {
      let url = `virtualLearning/${id}/slots`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getRegisterEventSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(getRegisterEventFailure(e.message));
    }
  };
}

export function bookEventRequest() {
  return {
    type: BOOK_EVENT_REQUEST,
    status: REQUESTING,
  };
}

export function bookEventSuccess(paymentMessage) {
  return {
    type: BOOK_EVENT_SUCCESS,
    status: SUCCESS,
    paymentMessage,
  };
}

export function bookEventFailure(error) {
  return {
    type: BOOK_EVENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function bookEvent(id, details) {
  return async (dispatch) => {
    dispatch(bookEventRequest());
    try {
      let url = `virtualLearning/${id}/booking`;
      const result = await post(url, details);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(bookEventSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(bookEventFailure(e.message));
    }
  };
}

export function paymentStatusRequest() {
  return {
    type: PAYMENT_STATUS_REQUEST,
    status: REQUESTING,
  };
}

export function paymentStatusSuccess(paymentStatus) {
  return {
    type: PAYMENT_STATUS_SUCCESS,
    status: SUCCESS,
    paymentStatus,
  };
}

export function paymentStatusFailure(error) {
  return {
    type: PAYMENT_STATUS_FAILURE,
    status: ERROR,
    error,
  };
}

export function paymentStatus(id, details) {
  return async (dispatch) => {
    dispatch(paymentStatusRequest());
    try {
      let url = `virtualLearning/${id}/booking`;
      const result = await put(url, details);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(paymentStatusSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(paymentStatusFailure(e.message));
    }
  };
}
export function getCityRequest() {
  return {
    type: GET_CITY_LIST_REQUEST,
    status: REQUESTING,
  };
}

export function getCitySuccess(cityList) {
  return {
    type: GET_CITY_LIST_SUCCESS,
    status: SUCCESS,
    cityList,
  };
}

export function getCityFailure(error) {
  return {
    type: GET_CITY_LIST_FAILURE,
    status: ERROR,
    error,
  };
}

export function getCityList(city) {
  let cityData = city ? city : null;
  return async (dispatch) => {
    dispatch(getCityRequest());
    try {
      let url = `citylists`;
      if (cityData) {
        url = `citylists?name=${cityData}&limit=10&page=1`;
      }
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getCitySuccess(resultJson));
    } catch (e) {
      return dispatch(getCityFailure(e.message));
    }
  };
}

export function addUserIntrestRequest() {
  return {
    type: ADD_USER_INTEREST_REQUEST,
    status: REQUESTING,
  };
}

export function addUserIntrestSuccess(userDetails) {
  return {
    type: ADD_USER_INTEREST_SUCCESS,
    status: SUCCESS,
    userDetails,
  };
}

export function addUserIntrestFailure(error) {
  return {
    type: ADD_USER_INTEREST_FAILURE,
    status: ERROR,
    error,
  };
}

export function addUserIntrest(value, link) {
  return async (dispatch) => {
    dispatch(addUserIntrestRequest());
    try {
      let url = `virtualLearning/interest`;
      const result = await post(url, value);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, resultJson.message));
      if (link) {
        dispatch(getEventDetails(value.eventId));
      }
      return dispatch(addUserIntrestSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(addUserIntrestFailure(e.message));
    }
  };
}
export function getIntrestRequest() {
  return {
    type: GET_INTRESET_REQUEST,
    status: REQUESTING,
  };
}
export function getIntrestSuccess(interset) {
  return {
    type: GET_INTRESET_SUCCESS,
    status: SUCCESS,
    interset,
  };
}

export function getIntrestFailure(error) {
  return {
    type: GET_INTRESET_FAILURE,
    status: ERROR,
    error,
  };
}

export function getIntrest(id, type) {
  return async (dispatch) => {
    dispatch(getIntrestRequest());
    try {
      let url =
        typeof type == 'undefined'
          ? `virtualLearning/labels/${id}`
          : `virtualLearning/labels/${id}?vlType=${type}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      //dispatch(displayToast(resultJson.message));
      return dispatch(getIntrestSuccess(resultJson));
    } catch (e) {
      return dispatch(getIntrestFailure(e.message));
    }
  };
}

export function subscriptionRequest() {
  return {
    type: SUBSCRIPTION_REQUEST,
    status: REQUESTING,
  };
}

export function subscriptionSuccess(userDetails) {
  return {
    type: SUBSCRIPTION_SUCCESS,
    status: SUCCESS,
    userDetails,
  };
}

export function subscriptionFailure(error) {
  return {
    type: SUBSCRIPTION_FAILURE,
    status: ERROR,
    error,
  };
}
export function getsubscription(data) {
  return async (dispatch) => {
    dispatch(subscriptionRequest());
    try {
      let url = `subscribe`;
      const result = await post(url, data);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(subscriptionSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(subscriptionFailure(e.message));
    }
  };
}

export const CLEAR_ALL_REQUEST = 'CLEAR_ALL_REQUEST';

export function clearAllRequest() {
  return {
    type: CLEAR_ALL_REQUEST,
  };
}

export function clearAll() {
  return async (dispatch) => {
    dispatch(clearAllRequest());
  };
}
