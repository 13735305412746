import * as verifyemailAction from "../actions/verifyemail.action";

const verifyemail = (
  state = {
    status: null,
    error: null,
    loading: false,
    verificationDetails: null,
    searchData: null,
    searchLoader: false
  },
  action
) => {
  switch (action.type) {
    case verifyemailAction.GET_SEARCH_LIST_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        searchData: null,
        loader: true,
        searchLoader: true
      });

    case verifyemailAction.GET_SEARCH_LIST_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        searchLoader: false,
        searchData: action.searchData
      });

    case verifyemailAction.GET_SEARCH_LIST_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        searchLoader: false,
        error: action.error
      });

    case verifyemailAction.GET_EMAIL_VERIFICATION_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: true
      });

    case verifyemailAction.GET_EMAIL_VERIFICATION_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        verificationDetails: action.verificationDetails
      });

    case verifyemailAction.GET_EMAIL_VERIFICATION_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error
      });
    case verifyemailAction.POST_EMAIL_VERIFICATION_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: true
      });

    case verifyemailAction.POST_EMAIL_VERIFICATION_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        postVerificationDetails: action.postVerificationDetails
      });

    case verifyemailAction.POST_EMAIL_VERIFICATION_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error
      });
    default:
      return state;
  }
};
export default verifyemail;
