import {
  SUCCESS,
  REQUESTING,
  ERROR,
  USER_DETAILS,
} from '../utils/constant';
import { get, post, deleteData } from '../utils/apiRequest.js';
import * as Cookie from '../utils/Cookie';
import { displayToast } from '../components/modules/toast.actions';
import { getLabelFailure, GET_LABEL_FAILURE } from './event.action';
export const UPDATE_IMAGE_ARR = 'updateImageArr';

export const GET_USER_INTRESET_REQUEST = 'GET_USER_INTRESET_REQUEST';
export const GET_USER_INTRESET_SUCCESS = 'GET_USER_INTRESET_SUCCESS';
export const GET_USER_INTRESET_FAILURE = 'GET_USER_INTRESET_FAILURE';

export const ADD_USER_INTEREST_REQUEST = 'ADD_USER_INTEREST_REQUEST';
export const ADD_USER_INTEREST_SUCCESS = 'ADD_USER_INTEREST_SUCCESS';
export const ADD_USER_INTEREST_FAILURE = 'ADD_USER_INTEREST_FAILURE';

export const GET_TOP_PICKS_REQUEST = 'GET_TOP_PICKS_REQUEST';
export const GET_TOP_PICKS_SUCCESS = 'GET_TOP_PICKS_SUCCESS';
export const GET_TOP_PICKS_FAILURE = 'GET_TOP_PICKS_FAILURE';

export const GET_RECOMMANDATION_REQUEST = 'GET_RECOMMANDATION_REQUEST';
export const GET_RECOMMANDATION_SUCCESS = 'GET_RECOMMANDATION_SUCCESS';
export const GET_RECOMMANDATION_FAILURE = 'GET_RECOMMANDATION_FAILURE';

export const POST_FEED_REQUEST = 'POST_FEED_REQUEST';
export const POST_FEED_SUCCESS = 'POST_FEED__SUCCESS';
export const POST_FEED_FAILURE = 'POST_FEED__FAILURE';

export const GET_FEED_REQUEST = 'GET_FEED_REQUEST';
export const GET_FEED_SUCCESS = 'GET_FEED_SUCCESS';
export const GET_FEED_FAILURE = 'GET_FEED_FAILURE';

export const GET_USER_PROFILE_INFO_REQUEST = 'GET_USER_PROFILE_INFO_REQUEST';
export const GET_USER_PROFILE_INFO_SUCCESS = 'GET_USER_PROFILE_INFO_SUCCESS';
export const GET_USER_PROFILE_INFO_FAILURE = 'GET_USER_PROFILE_INFO_FAILURE';

export const GET_LABELS_REQUEST = 'GET_LABELS_REQUEST';
export const GET_LABELS_SUCCESS = 'GET_LABELS_SUCCESS';
export const GET_LABELS_FAILURE = 'GET_LABELS_FAILURE';

export const POST_FEED_LIKE_REQUEST = 'POST_FEED_LIKE_REQUEST';
export const POST_FEED_LIKE_SUCCESS = 'POST_FEED_LIKE_SUCCESS';
export const POST_FEED_LIKE_FAILURE = 'POST_FEED_LIKE_FAILURE';

export const POST_FEED_COMMENT_LIKE_REQUEST = 'POST_FEED_COMMENT_LIKE_REQUEST';
export const POST_FEED_COMMENT_LIKE_SUCCESS = 'POST_FEED_COMMENT_LIKE_SUCCESS';
export const POST_FEED_COMMENT_LIKE_FAILURE = 'POST_FEED_COMMENT_LIKE_FAILURE';

export const GET_FEED_COMMENT_REQUEST = 'GET_FEED_COMMENT_REQUEST';
export const GET_FEED_COMMENT_SUCCESS = 'GET_FEED_COMMENT_SUCCESS';
export const GET_FEED_COMMENT_FAILURE = 'GET_FEED_COMMENT_FAILURE';

export const POST_FEED_COMMENT_REQUEST = 'POST_FEED_COMMENT_REQUEST';
export const POST_FEED_COMMENT_SUCCESS = 'POST_FEED_COMMENT_SUCCESS';
export const POST_FEED_COMMENT_FAILURE = 'POST_FEED_COMMENT_FAILURE';

export const POST_POLL_ANSWER_REQUEST = 'POST_POLL_ANSWER_REQUEST';
export const POST_POLL_ANSWER_SUCCESS = 'POST_POLL_ANSWER_SUCCESS';
export const POST_POLL_ANSWER_FAILURE = 'POST_POLL_ANSWER_FAILURE';

export const POST_FEED_BOOKMARK_REQUEST = 'POST_FEED_BOOKMARK_REQUEST';
export const POST_FEED_BOOKMARK_SUCCESS = 'POST_FEED_BOOKMARK_SUCCESS';
export const POST_FEED_BOOKMARK_FAILURE = 'POST_FEED_BOOKMARK_FAILURE';

export const DELETE_FEED_REQUEST = 'DELETE_FEED_REQUEST';
export const DELETE_FEED_SUCCESS = 'DELETE_FEED_SUCCESS';
export const DELETE_FEED_FAILURE = 'DELETE_FEED_FAILURE';

export const DELETE_FEED_COMMENT_REQUEST = 'DELETE_FEED_COMMENT_REQUEST';
export const DELETE_FEED_COMMENT_SUCCESS = 'DELETE_FEED_COMMENT_SUCCESS';
export const DELETE_FEED_COMMENT_FAILURE = 'DELETE_FEED_COMMENT_FAILURE';

export const GET_FEED_DETAILS_REQUEST = 'GET_FEED_DETAILS_REQUEST';
export const GET_FEED_DETAILS_SUCCESS = 'GET_FEED_DETAILS_SUCCESS';
export const GET_FEED_DETAILS_FAILURE = 'GET_FEED_DETAILS_FAILURE';

export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_FAILURE = 'REPORT_FAILURE';

export const GET_FEED_COMMENTS_OF_COMMENT_REQUEST =
  'GET_FEED_COMMENTS_OF_COMMENT_REQUEST';
export const GET_FEED_COMMENTS_OF_COMMENT_SUCCESS =
  'GET_FEED_COMMENTS_OF_COMMENT_SUCCESS';
export const GET_FEED_COMMENTS_OF_COMMENT_FAILURE =
  'GET_FEED_COMMENTS_OF_COMMENT_FAILURE';

export const POST_FEED_COMMENT_OF_COMMENT_REQUEST =
  'POST_FEED_COMMENT_OF_COMMENT_REQUEST';
export const POST_FEED_COMMENT_OF_COMMENT_SUCCESS =
  'POST_FEED_COMMENT_OF_COMMENT_SUCCESS';
export const POST_FEED_COMMENT_OF_COMMENT_FAILURE =
  'POST_FEED_COMMENT_OF_COMMENT_FAILURE';
export function updateImageArr(data) {
  return {
    type: UPDATE_IMAGE_ARR,
    data,
  };
}

export function addUserInterestRequest() {
  return {
    type: ADD_USER_INTEREST_REQUEST,
    status: REQUESTING,
  };
}

export function addUserInterestSuccess(userDetails) {
  return {
    type: ADD_USER_INTEREST_SUCCESS,
    status: SUCCESS,
    userDetails,
  };
}

export function addUserInterestFailure(error) {
  return {
    type: ADD_USER_INTEREST_FAILURE,
    status: ERROR,
    error,
  };
}

export function addUserInterest(value) {
  return async (dispatch) => {
    dispatch(addUserInterestRequest());
    try {
      let user = Cookie.getCookie(USER_DETAILS);
      let parsedUser = user ? JSON.parse(user) : null;
      let userId = parsedUser ? parsedUser.userId : null;
      let url = `users/${userId}/interest`;
      const result = await post(url, value);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      // window.location.reload();
      return dispatch(addUserInterestSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(addUserInterestFailure(e.message));
    }
  };
}

export function getUserInterestRequest() {
  return {
    type: GET_USER_INTRESET_REQUEST,
    status: REQUESTING,
  };
}

export function getUserInterestSuccess(userDetails) {
  return {
    type: GET_USER_INTRESET_SUCCESS,
    status: SUCCESS,
    userDetails,
  };
}

export function getUserInterestFailure(error) {
  return {
    type: GET_USER_INTRESET_FAILURE,
    status: ERROR,
    error,
  };
}

export function getUserInterest() {
  return async (dispatch) => {
    dispatch(getUserInterestRequest());
    try {
      let user = Cookie.getCookie(USER_DETAILS);
      let parsedUser = user ? JSON.parse(user) : null;
      let userId = parsedUser ? parsedUser.userId : null;
      let url = userId ? `users/${userId}/interest` : `interests?limit=200`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getUserInterestSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(getUserInterestFailure(e.message));
    }
  };
}

export function getTopPicksRequest() {
  return {
    type: GET_TOP_PICKS_REQUEST,
    status: REQUESTING,
  };
}

export function getTopPicksSuccess(topPicks) {
  return {
    type: GET_TOP_PICKS_SUCCESS,
    status: SUCCESS,
    topPicks,
  };
}

export function getTopPicksFailure(error) {
  return {
    type: GET_TOP_PICKS_FAILURE,
    status: ERROR,
    error,
  };
}

export function getTopPicks() {
  return async (dispatch) => {
    dispatch(getTopPicksRequest());
    try {
      let user = Cookie.getCookie(USER_DETAILS);
      let parsedUser = user ? JSON.parse(user) : null;
      let userId = parsedUser ? parsedUser.userId : null;
      let url = `feeds/top-pick/${userId}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getTopPicksSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(getTopPicksFailure(e.message));
    }
  };
}

export function getRecommendRequest() {
  return {
    type: GET_RECOMMANDATION_REQUEST,
    status: REQUESTING,
  };
}

export function getRecommendSuccess(recommands) {
  return {
    type: GET_RECOMMANDATION_SUCCESS,
    status: SUCCESS,
    recommands,
  };
}

export function getRecommendFailure(error) {
  return {
    type: GET_RECOMMANDATION_FAILURE,
    status: ERROR,
    error,
  };
}

export function getRecommend() {
  return async (dispatch) => {
    dispatch(getRecommendRequest());
    try {
      let user = Cookie.getCookie(USER_DETAILS);
      let parsedUser = user ? JSON.parse(user) : null;
      let userId = parsedUser ? parsedUser.userId : null;
      let url = `feeds/recommended/${userId}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getRecommendSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(getRecommendFailure(e.message));
    }
  };
}

export function getFeedRequest() {
  return {
    type: GET_FEED_REQUEST,
    status: REQUESTING,
  };
}

export function getFeedSuccess(feeds, oldFeed) {
  return {
    type: GET_FEED_SUCCESS,
    status: SUCCESS,
    feeds,
    oldFeed: oldFeed ? oldFeed : [],
  };
}

export function getFeedFailure(error) {
  return {
    type: GET_FEED_FAILURE,
    status: ERROR,
    error,
  };
}

export function getFeed(id, oldFeed) {
  return async (dispatch) => {
    dispatch(getFeedRequest());
    try {
      let url = `feeds/allfeeds?page=${id}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getFeedSuccess(resultJson, oldFeed));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(getFeedFailure(e.message));
    }
  };
}
export function postFeedRequest() {
  return {
    type: POST_FEED_REQUEST,
    status: REQUESTING,
  };
}

export function postFeedSuccess(feedDetails) {
  return {
    type: POST_FEED_SUCCESS,
    status: SUCCESS,
    feedDetails,
  };
}

export function postFeedFailure(error) {
  return {
    type: POST_FEED_FAILURE,
    status: ERROR,
    error,
  };
}

export function postFeed(postFeedDetails) {
  return async (dispatch) => {
    dispatch(postFeedRequest());
    try {
      let url = `feeds`;
      const result = await post(url, postFeedDetails);
      const resultJson = await result.data;
      if (result && result.code === 500) {
        throw new Error(result.message);
      }
      dispatch(displayToast(1, 'Post added successfully'));
      window.location.reload();
      return dispatch(postFeedSuccess(resultJson));
    } catch (e) {
      e.response
        ? e.response.data && dispatch(displayToast(3, e.response.data.message))
        : dispatch(displayToast(3, e.message));
      return dispatch(postFeedFailure(e.message));
    }
  };
}

export function getUserProfileInfoRequest() {
  return {
    type: GET_USER_PROFILE_INFO_REQUEST,
    status: REQUESTING,
  };
}

export function getUserProfileInfoSuccess(userProfileInfo) {
  return {
    type: GET_USER_PROFILE_INFO_SUCCESS,
    status: SUCCESS,
    userProfileInfo,
  };
}

export function getUserProfileInfoFailure(error) {
  return {
    type: GET_USER_PROFILE_INFO_FAILURE,
    status: ERROR,
    error,
  };
}

export function getUserProfileInfo() {
  return async (dispatch) => {
    dispatch(getUserProfileInfoRequest());
    try {
      const user = Cookie.getCookie(USER_DETAILS);
      const parsedUser = user ? JSON.parse(user) : null;
      const userId = parsedUser ? parsedUser.userId : null;
      const url = `users/${userId}/info`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getUserProfileInfoSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(getUserProfileInfoFailure(e.message));
    }
  };
}

export function getLabelsRequesting() {
  return {
    type: GET_LABELS_REQUEST,
    status: REQUESTING,
  };
}

export function getLabelsSuccess(labelsList) {
  return {
    type: GET_LABELS_SUCCESS,
    status: SUCCESS,
    labelsList,
  };
}

export function getLabelsFailure(error) {
  return {
    type: GET_LABEL_FAILURE,
    status: ERROR,
    error,
  };
}

export function getLabels() {
  return async (dispatch) => {
    dispatch(getLabelsRequesting());
    try {
      const url = `labels?limit=200`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getLabelsSuccess(resultJson));
    } catch (e) {
      //dispatch(displayToast(e.message));
      return dispatch(getLabelFailure(e.message));
    }
  };
}

export function postFeedLikeRequest() {
  return {
    type: POST_FEED_LIKE_REQUEST,
    status: REQUESTING,
  };
}

export function postFeedLikeSuccess(postLikeDetails, oldLikes) {
  return {
    type: POST_FEED_LIKE_SUCCESS,
    status: SUCCESS,
    postLikeDetails,
    oldLikes,
  };
}

export function postFeedLikeFailure(error) {
  return {
    type: POST_FEED_LIKE_FAILURE,
    status: ERROR,
    error,
  };
}

export function postFeedLike(feedsDetails, oldLikes) {
  let id = feedsDetails.feedId;
  return async (dispatch) => {
    dispatch(postFeedLikeRequest());
    try {
      let url = `feeds/like`;
      const result = await post(url, feedsDetails);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(postFeedLikeSuccess({ [id]: resultJson.data }, oldLikes));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(postFeedLikeFailure(e.message));
    }
  };
}

export function getFeedCommentRequest() {
  return {
    type: GET_FEED_COMMENT_REQUEST,
    status: REQUESTING,
  };
}

export function getFeedCommentSuccess(comments) {
  return {
    type: GET_FEED_COMMENT_SUCCESS,
    status: SUCCESS,
    comments,
  };
}

export function getFeedCommentFailure(error) {
  return {
    type: GET_FEED_COMMENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function getFeedComment(feedId) {
  return async (dispatch) => {
    dispatch(getFeedCommentRequest());
    try {
      let url = `feeds/${feedId}/comments`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getFeedCommentSuccess({ [feedId]: resultJson }));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(getFeedCommentFailure(e.message));
    }
  };
}

export function postFeedCommentRequest() {
  return {
    type: POST_FEED_COMMENT_REQUEST,
    status: REQUESTING,
  };
}

export function postFeedCommentSuccess(feedPostCommentDetails) {
  return {
    type: POST_FEED_COMMENT_SUCCESS,
    status: SUCCESS,
    feedPostCommentDetails,
  };
}

export function postFeedCommentFailure(error) {
  return {
    type: POST_FEED_COMMENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function postFeedComment(feedsCommentDetails, id) {
  return async (dispatch) => {
    dispatch(postFeedCommentRequest());
    try {
      let url = `feeds/comment`;
      const result = await post(url, feedsCommentDetails);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(postFeedCommentSuccess({ resultJson }));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(postFeedCommentFailure(e.message));
    }
  };
}

export function postPollAnswerRequest() {
  return {
    type: POST_POLL_ANSWER_REQUEST,
    status: REQUESTING,
  };
}

export function postPollAnswerSuccess(pollAnswerDetails, oldPostData) {
  return {
    type: POST_POLL_ANSWER_SUCCESS,
    status: SUCCESS,
    pollAnswerDetails,
    oldPostData,
  };
}

export function postPollAnswerFailure(error) {
  return {
    type: POST_POLL_ANSWER_FAILURE,
    status: ERROR,
    error,
  };
}

export function postPollAnswer(id, feedId, oldPostData) {
  let user = Cookie.getCookie(USER_DETAILS);
  let parsedUser = user ? JSON.parse(user) : null;
  let userId = parsedUser ? parsedUser.userId : null;
  return async (dispatch) => {
    dispatch(postPollAnswerRequest());
    try {
      let url = `feeds/${feedId}/poll/option/${id}`;
      const result = await post(url, { userId: userId });
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, 'Your selection has been recorded'));
      return dispatch(postPollAnswerSuccess({ [id]: resultJson }, oldPostData));
    } catch (e) {
      return dispatch(postPollAnswerFailure(e.message));
    }
  };
}

export function postFeedBookmarkRequest() {
  return {
    type: POST_FEED_BOOKMARK_REQUEST,
    status: REQUESTING,
  };
}

export function postFeedBookmarkSuccess(feedBookmarkDetails) {
  return {
    type: POST_FEED_BOOKMARK_SUCCESS,
    status: SUCCESS,
    feedBookmarkDetails,
  };
}

export function postFeedBookmarkFailure(error) {
  return {
    type: POST_FEED_BOOKMARK_FAILURE,
    status: ERROR,
    error,
  };
}

export function postFeedBookmark(feedDetails) {
  return async (dispatch) => {
    dispatch(postFeedBookmarkRequest());
    try {
      let url = `feed/bookmark`;
      const result = await post(url, feedDetails);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(postFeedBookmarkSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(postFeedBookmarkFailure(e.message));
    }
  };
}

export function deleteFeedCommentRequest() {
  return {
    type: DELETE_FEED_COMMENT_REQUEST,
    status: REQUESTING,
  };
}

export function deleteFeedCommentSuccess(comments) {
  return {
    type: DELETE_FEED_COMMENT_SUCCESS,
    status: SUCCESS,
    comments,
  };
}

export function deleteFeedCommentFailure(error) {
  return {
    type: DELETE_FEED_COMMENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function deleteFeedComment(id) {
  return async (dispatch) => {
    dispatch(deleteFeedCommentRequest());
    try {
      let url = `feeds/comment/${id}`;
      const result = await deleteData(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, 'Comment deleted successfully'));
      return dispatch(deleteFeedCommentSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(deleteFeedCommentFailure(e.message));
    }
  };
}

export function deleteFeedRequest() {
  return {
    type: DELETE_FEED_REQUEST,
    status: REQUESTING,
  };
}

export function deleteFeedSuccess(feeds) {
  return {
    type: DELETE_FEED_SUCCESS,
    status: SUCCESS,
    feeds,
  };
}

export function deleteFeedFailure(error) {
  return {
    type: DELETE_FEED_FAILURE,
    status: ERROR,
    error,
  };
}

export function deleteFeed(id) {
  return async (dispatch) => {
    dispatch(deleteFeedRequest());
    try {
      let url = `feeds/${id}`;
      const result = await deleteData(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, 'Post deleted successfully'));
      window.location.reload();
      return dispatch(deleteFeedSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(deleteFeedFailure(e.message));
    }
  };
}

export function getFeedDetailsRequest() {
  return {
    type: GET_FEED_DETAILS_REQUEST,
    status: REQUESTING,
  };
}

export function getFeedDetailsSuccess(feedDetails) {
  return {
    type: GET_FEED_DETAILS_SUCCESS,
    status: SUCCESS,
    feedDetails,
  };
}

export function getFeedDetailsFailure(error, errorMessage) {
  return {
    type: GET_FEED_DETAILS_FAILURE,
    status: ERROR,
    errorMessage: errorMessage,
    error,
  };
}

export function getFeedDetails(feedId) {
  return async (dispatch) => {
    dispatch(getFeedDetailsRequest());
    try {
      let url = `feeds/${feedId}`;
      const result = await get(url);
      if (result && result.code === 500) {
        throw new Error(result.message);
      }
      const resultJson = await result.data;
      return dispatch(getFeedDetailsSuccess(resultJson));
    } catch (e) {
      // e.response &&
      //   e.response.data &&
      //   dispatch(displayToast(e.response.data.message));
      return dispatch(
        getFeedDetailsFailure(e.message, e.response.data.message)
      );
    }
  };
}

export function reportRequest() {
  return {
    type: REPORT_REQUEST,
    status: REQUESTING,
  };
}

export function reportSuccess(reportDetail) {
  return {
    type: REPORT_SUCCESS,
    status: SUCCESS,
    reportDetail,
  };
}

export function reportFailure(error) {
  return {
    type: REPORT_FAILURE,
    status: ERROR,
    error,
  };
}

export function report(data) {
  return async (dispatch) => {
    dispatch(reportRequest());
    try {
      let url = `report`;
      const result = await post(url, data);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, resultJson.message));
      return dispatch(reportSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(reportFailure(e.message));
    }
  };
}
export function getFeedCommentsOfCommentRequest() {
  return {
    type: GET_FEED_COMMENTS_OF_COMMENT_REQUEST,
    status: REQUESTING,
  };
}

export function getFeedCommentsOfCommentSuccess(commentsOfComment, oldComment) {
  return {
    type: GET_FEED_COMMENTS_OF_COMMENT_SUCCESS,
    status: SUCCESS,
    commentsOfComment,
    oldComment: oldComment ? oldComment : [],
  };
}

export function getFeedCommentsOfCommentFailure(error) {
  return {
    type: GET_FEED_COMMENTS_OF_COMMENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function getFeedCommentsOfComment(feedID, parentId, oldComment) {
  return async (dispatch) => {
    dispatch(getFeedCommentsOfCommentRequest());
    try {
      let url = `feeds/${feedID}/comments/${parentId}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(
        getFeedCommentsOfCommentSuccess({ [parentId]: resultJson }, oldComment)
      );
    } catch (e) {
      // dispatch(displayToast("Error please retry again"));
      return dispatch(getFeedCommentsOfCommentFailure(e.message));
    }
  };
}
export function postFeedCommentsOfCommentRequest() {
  return {
    type: POST_FEED_COMMENT_OF_COMMENT_REQUEST,
    status: REQUESTING,
  };
}

export function postFeedCommentsOfCommentSuccess(
  feedPostCommentsOfCommentDetails
) {
  return {
    type: POST_FEED_COMMENT_OF_COMMENT_SUCCESS,
    status: SUCCESS,
    feedPostCommentsOfCommentDetails,
  };
}

export function postFeedCommentsOfCommentFailure(error) {
  return {
    type: POST_FEED_COMMENT_OF_COMMENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function postFeedCommentsOfComment(feedsCommentOfCommentDetails, id) {
  return async (dispatch) => {
    dispatch(postFeedCommentsOfCommentRequest());
    try {
      let url = `feeds/comment`;
      const result = await post(url, feedsCommentOfCommentDetails);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(postFeedCommentsOfCommentSuccess({ [id]: resultJson }));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(postFeedCommentsOfCommentFailure(e.message));
    }
  };
}
export function postFeedCommentLikeRequest() {
  return {
    type: POST_FEED_COMMENT_LIKE_REQUEST,
    status: REQUESTING,
  };
}

export function postFeedCommentLikeSuccess(postLikeDetails, oldLikes) {
  return {
    type: POST_FEED_COMMENT_LIKE_SUCCESS,
    status: SUCCESS,
    postLikeDetails,
    oldLikes,
  };
}

export function postFeedCommentLikeFailure(error) {
  return {
    type: POST_FEED_COMMENT_LIKE_FAILURE,
    status: ERROR,
    error,
  };
}

export function postFeedCommentLike(commentId, feedsDetails, oldLikes) {
  return async (dispatch) => {
    dispatch(postFeedCommentLikeRequest());
    try {
      let url = `feeds/comment/${commentId}/like`;
      const result = await post(url, feedsDetails);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(
        postFeedCommentLikeSuccess({ [commentId]: resultJson.data }, oldLikes)
      );
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(postFeedCommentLikeFailure(e.message));
    }
  };
}
