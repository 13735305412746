import {
  SUCCESS,
  REQUESTING,
  ERROR,
} from "../utils/constant";
import { get } from "../utils/apiRequest.js";

export const GET_BOOKMARK_REQUEST = "GET_BOOKMARK_REQUEST";
export const GET_BOOKMARK_SUCCESS = "GET_BOOKMARK_SUCCESS";
export const GET_BOOKMARK_FAILURE = "GET_BOOKMARK_FAILURE";

export function getBookmarkRequest() {
  return {
    type: GET_BOOKMARK_REQUEST,
    status: REQUESTING
  };
}

export function getBookmarkSuccess(bookMarkDetails, oldBlog) {
  return {
    type: GET_BOOKMARK_SUCCESS,
    status: SUCCESS,
    bookMarkDetails,
    oldBlog: oldBlog ? oldBlog : []
  };
}

export function getBookmarkFailure(error) {
  return {
    type: GET_BOOKMARK_FAILURE,
    status: ERROR,
    error
  };
}

export function getBookmark(id, oldBlog) {
  return async dispatch => {
    dispatch(getBookmarkRequest());
    try {
      let url = `blogs/bookmark`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getBookmarkSuccess(resultJson, oldBlog));
    } catch (e) {
      return dispatch(getBookmarkFailure(e.message));
    }
  };
}
