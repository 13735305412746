// users/sendVerificationMail
import { SUCCESS, REQUESTING, ERROR } from '../utils/constant';
import { get, post } from '../utils/apiRequest';
import { displayToast } from '../components/modules/toast.actions';
import { ACCESS_TOKEN, USER_DETAILS } from '../utils/constant';
import * as Cookie from '../utils/Cookie';
export const GET_EMAIL_VERIFICATION_REQUEST = 'GET_EMAIL_VERIFICATION_REQUEST';
export const GET_EMAIL_VERIFICATION_SUCCESS = 'GET_EMAIL_VERIFICATION_SUCCESS';
export const GET_EMAIL_VERIFICATION_FAILURE = 'GET_EMAIL_VERIFICATION_FAILURE';

export const POST_EMAIL_VERIFICATION_REQUEST =
  'POST_EMAIL_VERIFICATION_REQUEST';
export const POST_EMAIL_VERIFICATION_SUCCESS =
  'POST_EMAIL_VERIFICATION_SUCCESS';
export const POST_EMAIL_VERIFICATION_FAILURE =
  'POST_EMAIL_VERIFICATION_FAILURE';

export const GET_SEARCH_LIST_REQUEST = 'GET_SEARCH_LIST_REQUEST';
export const GET_SEARCH_LIST_SUCCESS = 'GET_SEARCH_LIST_SUCCESS';
export const GET_SEARCH_LIST_FAILURE = 'GET_SEARCH_LIST_FAILURE';

export function getSearchListRequest() {
  return {
    type: GET_SEARCH_LIST_REQUEST,
    status: REQUESTING,
  };
}

export function getSearchListSuccess(searchData) {
  return {
    type: GET_SEARCH_LIST_SUCCESS,
    status: SUCCESS,
    searchData,
  };
}

export function getSearchListFailure(error) {
  return {
    type: GET_SEARCH_LIST_FAILURE,
    status: ERROR,
    error,
  };
}

export function getSearchList(data) {
  var uri = encodeURIComponent(data);
  return async (dispatch) => {
    dispatch(getSearchListRequest());
    try {
      let url = `feeds/search?search=${uri}`;

      const result = await get(url);
      const resultJson = await result.data;

      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getSearchListSuccess(resultJson));
    } catch (e) {
      // dispatch(displayToast(e.message));
      return dispatch(getSearchListFailure(e.message));
    }
  };
}

export function getEmailVerificationRequest() {
  return {
    type: GET_EMAIL_VERIFICATION_REQUEST,
    status: REQUESTING,
  };
}

export function getEmailVerificationSuccess(verificationDetails) {
  return {
    type: GET_EMAIL_VERIFICATION_SUCCESS,
    status: SUCCESS,
    verificationDetails,
  };
}

export function getEmailVerificationFailure(error) {
  return {
    type: GET_EMAIL_VERIFICATION_FAILURE,
    status: ERROR,
    error,
  };
}

export function getEmailVerification() {
  return async (dispatch) => {
    dispatch(getEmailVerificationRequest());
    try {
      let url = `users/sendVerificationMail`;

      const result = await get(url);
      const resultJson = await result.data;

      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, 'Verification mail has been sent'));
      return dispatch(getEmailVerificationSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(getEmailVerificationFailure(e.message));
    }
  };
}

export function postEmailVerificationRequest() {
  return {
    type: POST_EMAIL_VERIFICATION_REQUEST,
    status: REQUESTING,
  };
}

export function postEmailVerificationSuccess(postVerificationDetails) {
  return {
    type: POST_EMAIL_VERIFICATION_SUCCESS,
    status: SUCCESS,
    postVerificationDetails,
  };
}

export function postEmailVerificationFailure(error) {
  return {
    type: POST_EMAIL_VERIFICATION_FAILURE,
    status: ERROR,
    error,
  };
}

export function postEmailVerification(reqBody) {
  return async (dispatch) => {
    dispatch(postEmailVerificationRequest());
    try {
      let url = `users/verifyMail`;

      const result = await post(url, reqBody);
      const resultJson = await result.data;

      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      Cookie.deleteCookie(ACCESS_TOKEN);
      Cookie.deleteCookie(USER_DETAILS);
      dispatch(displayToast(1, resultJson.message));
      return dispatch(postEmailVerificationSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(postEmailVerificationFailure(e.message));
    }
  };
}
