import React from 'react';
import loadable from '@loadable/component';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index.js';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'react-image-lightbox/style.css';
import * as serviceWorker from './serviceWorker';

const App = loadable(() => import('./App'));
const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(rootReducer);
const history = createBrowserHistory();

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store} history={history}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();