import {
  SUCCESS,
  REQUESTING,
  ERROR,
} from '../utils/constant';
import { get, post, deleteData } from '../utils/apiRequest.js';
import { displayToast } from '../components/modules/toast.actions';
export const GET_ALL_BLOGS_REQUEST = 'GET_ALL_BLOGS_REQUEST';
export const GET_ALL_BLOGS_SUCCESS = 'GET_ALL_BLOGS_SUCCESS';
export const GET_ALL_BLOGS_FAILURE = 'GET_ALL_BLOGS_FAILURE';

export const GET_BLOG_DETAILS_REQUEST = 'GET_BLOG_DETAILS_REQUEST';
export const GET_BLOG_DETAILS_SUCCESS = 'GET_BLOG_DETAILS_SUCCESS';
export const GET_BLOG_DETAILS_FAILURE = 'GET_BLOG_DETAILS_FAILURE';

export const POST_BLOG_LIKE_REQUEST = 'POST_BLOG_LIKE_REQUEST';
export const POST_BLOG_LIKE_SUCCESS = 'POST_BLOG_LIKE_SUCCESS';
export const POST_BLOG_LIKE_FAILURE = 'POST_BLOG_LIKE_FAILURE';

export const GET_SEARCH_BLOGS_REQUEST = "GET_SEARCH_BLOGS_REQUEST";
export const GET_SEARCH_BLOGS_SUCCESS = "GET_SEARCH_BLOGS_SUCCESS";
export const GET_SEARCH_BLOGS_FAILURE = "GET_SEARCH_BLOGS_FAILURE";

export const GET_BLOG_COMMENT_REQUEST = 'GET_BLOG_COMMENT_REQUEST';
export const GET_BLOG_COMMENT_SUCCESS = 'GET_BLOG_COMMENT_SUCCESS';
export const GET_BLOG_COMMENT_FAILURE = 'GET_BLOG_COMMENT_FAILURE';

export const POST_BLOG_COMMENT_REQUEST = 'POST_BLOG_COMMENT_REQUEST';
export const POST_BLOG_COMMENT_SUCCESS = 'POST_BLOG_COMMENT_SUCCESS';
export const POST_BLOG_COMMENT_FAILURE = 'POST_BLOG_COMMENT_FAILURE';

export const GET_BLOG_COMMENTS_OF_COMMENT_REQUEST =
  'GET_BLOG_COMMENTS_OF_COMMENT_REQUEST';
export const GET_BLOG_COMMENTS_OF_COMMENT_SUCCESS =
  'GET_BLOG_COMMENTS_OF_COMMENT_SUCCESS';
export const GET_BLOG_COMMENTS_OF_COMMENT_FAILURE =
  'GET_BLOG_COMMENTS_OF_COMMENT_FAILURE';

export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE';

export const DELETE_BLOG_COMMENT_REQUEST = 'DELETE_BLOG_COMMENT_REQUEST';
export const DELETE_BLOG_COMMENT_SUCCESS = 'DELETE_BLOG_COMMENT_SUCCESS';
export const DELETE_BLOG_COMMENT_FAILURE = 'DELETE_BLOG_COMMENT_FAILURE';

export const POST_BLOG_BOOKMARK_REQUEST = 'POST_BLOG_BOOKMARK_REQUEST';
export const POST_BLOG_BOOKMARK_SUCCESS = 'POST_BLOG_BOOKMARK_SUCCESS';
export const POST_BLOG_BOOKMARK_FAILURE = 'POST_BLOG_BOOKMARK_FAILURE';

export const GET_BLOG_FILTER_REQUEST = 'GET_BLOG_FILTER_REQUEST';
export const GET_BLOG_FILTER_SUCCESS = 'GET_BLOG_FILTER_SUCCESS';
export const GET_BLOG_FILTER_FAILURE = 'GET_BLOG_FILTER_FAILURE';

export const GET_BLOG_FILTER_DETAIL_REQUEST = 'GET_BLOG_FILTER_DETAIL_REQUEST';
export const GET_BLOG_FILTER_DETAIL_SUCCESS = 'GET_BLOG_FILTER_DETAIL_SUCCESS';
export const GET_BLOG_FILTER_DETAIL_FAILURE = 'GET_BLOG_FILTER_DETAIL_FAILURE';

export function filterDetailRequest() {
  return {
    type: GET_BLOG_FILTER_DETAIL_REQUEST,
    status: REQUESTING,
  };
}
export function filterDetailSuccess(blogFilterDetailList, oldDetails) {
  return {
    type: GET_BLOG_FILTER_DETAIL_SUCCESS,
    status: SUCCESS,
    blogFilterDetailList,
    oldDetails: oldDetails ? oldDetails : [],
  };
}
export function filterDetailFailure(error) {
  return {
    type: GET_BLOG_FILTER_DETAIL_FAILURE,
    status: ERROR,
    error,
  };
}

export function getBlogFilterDetailList(id, page, typeId, oldDetails) {
  return async (dispatch) => {
    dispatch(filterDetailRequest());
    try {
      let url = `blogs?filter={"blogCategory":[${id}],"contentTypeId": [${typeId}]}&page=${page}&limit=10`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(filterDetailSuccess(resultJson, oldDetails));
    } catch (e) {
      return dispatch(filterDetailFailure(e.message));
    }
  };
}

export function filterBlogRequest() {
  return {
    type: GET_BLOG_FILTER_REQUEST,
    status: REQUESTING,
  };
}

export function filterBlogSuccess(blogFilterList) {
  return {
    type: GET_BLOG_FILTER_SUCCESS,
    status: SUCCESS,
    blogFilterList,
  };
}

export function filterBlogFailure(error) {
  return {
    type: GET_BLOG_FILTER_FAILURE,
    status: ERROR,
    error,
  };
}

export function getBlogFilterList(type, search) {
  return async (dispatch) => {
    dispatch(filterBlogRequest());
    try {
      let url = `blogs/categories/1?search=${search}&id=${type}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(filterBlogSuccess(resultJson));
    } catch (e) {
      return dispatch(filterBlogFailure(e.message));
    }
  };
}

export function deleteBlogCommentRequest() {
  return {
    type: DELETE_BLOG_COMMENT_REQUEST,
    status: REQUESTING,
  };
}

export function deleteBlogCommentSuccess(comments) {
  return {
    type: DELETE_BLOG_COMMENT_SUCCESS,
    status: SUCCESS,
    comments,
  };
}

export function deleteBlogCommentFailure(error) {
  return {
    type: DELETE_BLOG_COMMENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function deleteBlogComment(id) {
  return async (dispatch) => {
    dispatch(deleteBlogCommentRequest());
    try {
      let url = `blogs/comment/${id}`;
      const result = await deleteData(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, 'Comment deleted successfully'));
      return dispatch(deleteBlogCommentSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, e.message));
      return dispatch(deleteBlogCommentFailure(e.message));
    }
  };
}

export function getAllBlogsRequest() {
  return {
    type: GET_ALL_BLOGS_REQUEST,
    status: REQUESTING,
  };
}

export function getAllBlogsSuccess(blogsDetails, oldBlog) {
  return {
    type: GET_ALL_BLOGS_SUCCESS,
    status: SUCCESS,
    blogsDetails: blogsDetails === 0 ? [] : blogsDetails,
    oldBlog: oldBlog ? oldBlog : [],
  };
}

export function getAllBlogsFailure(error) {
  return {
    type: GET_ALL_BLOGS_FAILURE,
    status: ERROR,
    error,
  };
}

export function getAllBlogs(search, id, limit, blogType, typeId, oldBlog) {
  return async (dispatch) => {
    dispatch(getAllBlogsRequest());
    try {
      let url = `blogs?page=${id}&limit=${limit}&filter={"blogCategory":[${blogType}],"contentTypeId":[${typeId}]}&keyword=${search}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }

      return dispatch(getAllBlogsSuccess(resultJson, oldBlog));
    } catch (e) {
      return dispatch(getAllBlogsFailure(e.message));
    }
  };
}

export function getBlogsDetailsRequest() {
  return {
    type: GET_BLOG_DETAILS_REQUEST,
    status: REQUESTING,
  };
}

export function getBlogsDetailsSuccess(blogsDetails) {
  return {
    type: GET_BLOG_DETAILS_SUCCESS,
    status: SUCCESS,
    blogsDetails,
  };
}

export function getBlogsDetailsFailure(error) {
  return {
    type: GET_BLOG_DETAILS_FAILURE,
    status: ERROR,
    error,
  };
}

export function getBlogsDetails(id, page) {
  return async (dispatch) => {
    dispatch(getBlogsDetailsRequest());
    try {
      let url = `blogs/${id}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(getBlogComment(id, page));
      return dispatch(getBlogsDetailsSuccess(resultJson));
    } catch (e) {
      // dispatch(displayToast("No data available"));
      return dispatch(getBlogsDetailsFailure(e.message));
    }
  };
}

export function postBlogLikeRequest() {
  return {
    type: POST_BLOG_LIKE_REQUEST,
    status: REQUESTING,
  };
}

export function postBlogLikeSuccess(blogLikeDetails, oldLikes) {
  return {
    type: POST_BLOG_LIKE_SUCCESS,
    status: SUCCESS,
    blogLikeDetails,
    oldLikes,
  };
}

export function postBlogLikeFailure(error) {
  return {
    type: POST_BLOG_LIKE_FAILURE,
    status: ERROR,
    error,
  };
}

export function postBlogLike(blogsDetails, oldLikes) {
  return async (dispatch) => {
    dispatch(postBlogLikeRequest());
    try {
      let url = `blogs/like`;
      const result = await post(url, blogsDetails);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(
        postBlogLikeSuccess(
          { [blogsDetails.blogId]: resultJson.data },
          oldLikes
        )
      );
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(postBlogLikeFailure(e.message));
    }
  };
}

export function getBlogCommentRequest() {
  return {
    type: GET_BLOG_COMMENT_REQUEST,
    status: REQUESTING,
  };
}

export function getBlogCommentSuccess(comments, oldComments) {
  return {
    type: GET_BLOG_COMMENT_SUCCESS,
    status: SUCCESS,
    comments,
    oldComments: oldComments ? oldComments : [],
  };
}

export function getBlogCommentFailure(error) {
  return {
    type: GET_BLOG_COMMENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function getBlogComment(blogID, page, oldComments) {
  return async (dispatch) => {
    dispatch(getBlogCommentRequest());
    try {
      let url = `blogs/comments/${blogID}?limit=10&page=${page}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getBlogCommentSuccess(resultJson, oldComments));
    } catch (e) {
      // dispatch(displayToast("Error please retry again"));
      return dispatch(getBlogCommentFailure(e.message));
    }
  };
}

export function postBlogCommentRequest() {
  return {
    type: POST_BLOG_COMMENT_REQUEST,
    status: REQUESTING,
  };
}

export function postBlogCommentSuccess(blogPostCommentDetails) {
  return {
    type: POST_BLOG_COMMENT_SUCCESS,
    status: SUCCESS,
    blogPostCommentDetails,
  };
}

export function postBlogCommentFailure(error) {
  return {
    type: POST_BLOG_COMMENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function postBlogComment(blogsCommentDetails) {
  return async (dispatch) => {
    dispatch(postBlogCommentRequest());
    try {
      let url = `blogs/comment`;
      const result = await post(url, blogsCommentDetails);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(postBlogCommentSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(postBlogCommentFailure(e.message));
    }
  };
}

export function getBlogCommentsOfCommentRequest() {
  return {
    type: GET_BLOG_COMMENTS_OF_COMMENT_REQUEST,
    status: REQUESTING,
  };
}

export function getBlogCommentsOfCommentSuccess(commentsOfComment) {
  return {
    type: GET_BLOG_COMMENTS_OF_COMMENT_SUCCESS,
    status: SUCCESS,
    commentsOfComment,
  };
}

export function getBlogCommentsOfCommentFailure(error) {
  return {
    type: GET_BLOG_COMMENTS_OF_COMMENT_FAILURE,
    status: ERROR,
    error,
  };
}

export function getBlogCommentsOfComment(blogID, parentId) {
  return async (dispatch) => {
    dispatch(getBlogCommentsOfCommentRequest());
    try {
      let url = `blogs/comments/${blogID}/${parentId}`;
      const result = await get(url);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      return dispatch(getBlogCommentsOfCommentSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(getBlogCommentsOfCommentFailure(e.message));
    }
  };
}
export function subscriptionRequest() {
  return {
    type: SUBSCRIPTION_REQUEST,
    status: REQUESTING,
  };
}

export function subscriptionSuccess(userDetails) {
  return {
    type: SUBSCRIPTION_SUCCESS,
    status: SUCCESS,
    userDetails,
  };
}

export function subscriptionFailure(error) {
  return {
    type: subscriptionFailure,
    status: ERROR,
    error,
  };
}

export function getsubscription(data) {
  return async (dispatch) => {
    dispatch(subscriptionRequest());
    try {
      let url = `subscribe`;
      const result = await post(url, data);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      dispatch(displayToast(1, resultJson.message));
      return dispatch(subscriptionSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(subscriptionFailure(e.message));
    }
  };
}
export function postBlogBookmarkRequest() {
  return {
    type: POST_BLOG_BOOKMARK_REQUEST,
    status: REQUESTING,
  };
}

export function postBlogBookmarkSuccess(blogBookmarkDetails) {
  return {
    type: POST_BLOG_BOOKMARK_SUCCESS,
    status: SUCCESS,
    blogBookmarkDetails,
  };
}

export function postBlogBookmarkFailure(error) {
  return {
    type: POST_BLOG_BOOKMARK_FAILURE,
    status: ERROR,
    error,
  };
}

export function postBlogBookmark(blogDetails) {
  return async (dispatch) => {
    dispatch(postBlogBookmarkRequest());
    try {
      let url = `blogs/bookmark`;
      const result = await post(url, blogDetails);
      const resultJson = await result.data;
      if (resultJson.error) {
        throw new Error(resultJson.message);
      }
      if (blogDetails.isBookmark) {
        dispatch(displayToast(1, 'Bookmarked Successfully'));
      } else {
        dispatch(displayToast(1, 'Bookmark Removed Successfully'));
      }
      return dispatch(postBlogBookmarkSuccess(resultJson));
    } catch (e) {
      dispatch(displayToast(3, 'Error please retry again'));
      return dispatch(postBlogBookmarkFailure(e.message));
    }
  };
}

export const CLEAR_ALL_REQUEST = 'CLEAR_ALL_REQUEST';

export function clearAllRequest() {
  return {
    type: CLEAR_ALL_REQUEST,
  };
}

export function clearAll() {
  return async (dispatch) => {
    dispatch(clearAllRequest());
  };
}
