import * as globalEventsAction from "../actions/globalEvent.action"

const globalEvents = (
    state = {
        events: null,
        allEvents: null,
        womenDay: null,
        data: null,
        deviceId: null
    },
    action
) => {
    switch (action.type) {
        case globalEventsAction.GET_GLOBAL_EVENTS_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })

        case globalEventsAction.GET_GLOBAL_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                events: action.payload
            })

        case globalEventsAction.GET_GLOBAL_EVENTS_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error,
            })

        case globalEventsAction.GET_WOMEN_DAY_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })

        case globalEventsAction.GET_WOMEN_DAY_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                womenDay: action.payload
            })

        case globalEventsAction.GET_WOMEN_DAY_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error
            })

        case globalEventsAction.GET_ALL_GLOBAL_EVENTS_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })
        
        case globalEventsAction.GET_ALL_GLOBAL_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                allEvents: action.payload
            })

        case globalEventsAction.GET_ALL_GLOBAL_EVENTS_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error,
            })

        case globalEventsAction.POST_USER_DATA_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })    
           
        case globalEventsAction.POST_USER_DATA_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                statusCode: action.statusCode
            })

        case globalEventsAction.POST_USER_DATA_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error
            })

        case globalEventsAction.POST_DEVICE_DETAILS_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })

        case globalEventsAction.POST_DEVICE_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                deviceId: action.deviceId
            })

        case globalEventsAction.POST_DEVICE_DETAILS_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error
            })

        case globalEventsAction.POST_CALENDAR_PAGE_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })

        case globalEventsAction.POST_CALENDAR_PAGE_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                calendarData: action.payload
            })

        case globalEventsAction.POST_CALENDAR_PAGE_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error
            })
        default:
            return state
    }
}
export default globalEvents