import * as corporateAction from "../actions/corporate.action";
const corporate = (
  state = {
    mediaData: null,
    allBlogsForCorporate: null,
    payload: null
  },
  action
) => {
  switch (action.type) {
    case corporateAction.GET_ALL_MEDIA_PARTNER_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: true,
      });

    case corporateAction.GET_ALL_MEDIA_PARTNER_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        mediaData: action.mediaPartnerData,
      });

    case corporateAction.GET_ALL_MEDIA_PARTNER_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });

    case corporateAction.GET_BLOGS_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        blogLoader: true,
        empty: false,
      });

    case corporateAction.GET_BLOGS_SUCCESS:
      let searchResults = [...action.oldBlog, ...action.blogsDetails]  
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        allBlogsForCorporate: searchResults,
        blogLoader: false,
        empty: action.blogsDetails.length === 0 ? true : false,
      });

    case corporateAction.GET_BLOGS_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        loading: false,
        error: action.error,
        blogLoader: false,
        empty:
          action.error === "Request failed with status code 404" ? true : false,
      });

      case corporateAction.GET_BLOG_FILTER_DETAIL_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
        Detailloading: true,
        DetailsEmpty: false,
      });

    case corporateAction.GET_BLOG_FILTER_DETAIL_SUCCESS:
      let oldBlogDetails = [
        ...action.oldDetails,
        ...action.blogFilterDetailList,
      ];
      return Object.assign({}, state, {
        status: action.status,
        Detailloading: false,
        blogFilterDetailList: oldBlogDetails,
        DetailsEmpty: action.blogFilterDetailList.length === 0 ? true : false,
      });

    case corporateAction.GET_BLOG_FILTER_DETAIL_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        Detailloading: false,
        error: action.error,
        DetailsEmpty: false,
      });

      case corporateAction.POST_STARTED_REQUEST:
        return Object.assign({}, state, {
          status: action.status,
          loading: true,
        });
      case corporateAction.POST_STARTED_SUCCESS:
        return Object.assign({}, state, {
          status: action.status,
          loading: false,
          formData: action.formData,
          payload: action.payload
        });
  
      case corporateAction.POST_STARTED_FAILURE:
        return Object.assign({}, state, {
          status: action.status,
          loading: false,
          error: action.error,
        });
    default:
      return state;
  }
};
export default corporate;
